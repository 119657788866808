import React, { FC, useContext, useMemo } from "react";
import { useRouter } from "next/router";
import styled, { ThemeContext } from "styled-components";
import { TenantContext } from "v3/context/tenant";
import { LocalizationContext } from "v3/context/localization";
import { SimpleHeader } from "@pepdirect/v3/ui/SimpleHeader";
import { Heading } from "@pepdirect/ui/heading";
import { InternalLink } from "@pepdirect/v3/ui/InternalLink";
import { BrandConfigCustomizationsImage } from "services/graphql/generated";
import { Device } from "@pepdirect/ui/Device";
import { fontWeights } from "@pepdirect/shared/types";
import { colors } from "@pepdirect/ui/variables";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Styled404 = styled.div`
  width: calc(100vw - 160px);
  max-width: 1200px;
  margin: 70px 0 120px;

  @media ${Device.mobile} {
    width: calc(100vw - 40px);
  }
`;

const StyledHeadingH1 = styled(Heading)`
  color: ${colors.darkGray};
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Text404 = styled.div`
  color: ${colors.lightestGray};
`;

const StyledHeadingH2 = styled(Heading)`
  color: ${colors.darkGray};
  margin-bottom: 32px;
  font-weight: ${fontWeights["400"]};
`;

const Links = styled.div`
  display: flex;
  gap: 30px;
`;

export const Custom404: FC = () => {
  const theme = useContext(ThemeContext);
  const { tenant } = useContext(TenantContext);
  const { localization } = useContext(LocalizationContext);
  const { pathname } = useRouter();

  const {
    headingText = "Sorry, that page can't be found",
    subHeadingText = "It could be a typo, it could be missing, or the link to your cart has changed.",
    homeButtonText = "Return to the home page",
    cartButtonText = "View your cart",
  } = localization?.components?.customNotFound || {};

  // since this can be used in both checkout and portal,
  // determine by reading the pathname
  const pageType = useMemo(() => {
    return pathname?.includes("[appId]") ? "checkout" : "portal";
  }, [pathname]);

  const { fallbackUrl = "" } = tenant || {};
  const logo =
    tenant?.brandConfig?.customizations?.images?.find(
      (img: BrandConfigCustomizationsImage) => img.name === "logoLargeLightUrl"
    ) || null;

  return (
    <Wrapper>
      <SimpleHeader
        fallbackUrl={fallbackUrl}
        logo={{ src: logo?.src || "", alt: logo?.alt || "Shop logo" }}
        type={pageType}
      />
      <Styled404>
        <StyledHeadingH1 level="h1" size="xl">
          <div>{headingText}</div>
          <Text404>404</Text404>
        </StyledHeadingH1>
        <StyledHeadingH2 level="h2" size="m">
          {subHeadingText}
        </StyledHeadingH2>
        <Links>
          <InternalLink href={`${fallbackUrl}`} color={theme.color.primary}>
            {homeButtonText}
          </InternalLink>
          <InternalLink
            href={`${fallbackUrl}/cart`}
            color={theme.color.primary}
          >
            {cartButtonText}
          </InternalLink>
        </Links>
      </Styled404>
    </Wrapper>
  );
};
